import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Section from "../components/section";
import SectionEntry from "../components/section-entry";
import { FiBriefcase } from "react-icons/fi";
import { graphql } from "gatsby";
import PageWrapper from "../components/common/page-wrapper";

// TODO: Build posts page
const PostsPage = ({ data }) => {
  const posts =
    data.allMarkdownRemark.edges.map(edge => ({
      ...edge.node.frontmatter,
      tags: edge.node.frontmatter.tags.split(",")
    })) || [];
  return (
    <Layout>
      <SEO
        title="Articles on Web Development"
        keywords={[
          `Babs Craig`,
          `Learn JavaScript`,
          `Babasanya Craig`,
          `Front End Development`,
          `React`,
          `JavaScript`,
          `Developer`
        ]}
      />
      <PageWrapper>
        <Section title="Posts" icon={FiBriefcase}>
          {posts.map(post => (
            <SectionEntry
              key={post.index}
              title={post.title}
              tags={post.tags}
              liveLink={post.path}
              renderBody={() => <p>{post.description}</p>}
            />
          ))}
        </Section>
      </PageWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___index] }
      filter: { frontmatter: { type: { eq: "post" }, published: { eq: true } } }
    ) {
      edges {
        node {
          frontmatter {
            path
            title
            description
            tags
            index
          }
        }
      }
    }
  }
`;

export default PostsPage;
