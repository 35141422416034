import React from "react";
import styled from "styled-components";
import StyledLink from "./common/styled-link";

const LinkEl = styled.span`
  margin-right: 1rem;
  font-size: 1rem;

  svg {
    position: relative;
    top: 1px;
    margin-left: 4px;
  }
`;

const Link = ({ to, name, openInNewWindow, icon }) => {
  const IconComponent = icon;
  const conditionalLinkProps = {
    ...(openInNewWindow ? { target: "_blank", rel: "noopener noreferrer" } : {})
  };
  return (
    <LinkEl>
      <StyledLink {...conditionalLinkProps} href={to}>
        {name}
        {icon && <IconComponent fontSize={"0.9rem"} />}
      </StyledLink>
    </LinkEl>
  );
};

export default Link;
