import React from "react";
import Link from "./link";
import styled from "styled-components";
import { FiCode, FiCast } from "react-icons/fi";
import PropTypes from "prop-types";
import SectionEntryTitle from "./section-entry-title";
import COLORS from "../constants/colors";

const SectionEntryEl = styled.div`
  margin-bottom: 3rem;

  p {
    font-size: 0.9rem;
    line-height: 1.8rem;
    color: ${COLORS.darkGrey};
  }
`;

const SectionEntry = ({ renderBody, liveLink, codeLink, ...titleProps }) => {
  return (
    <SectionEntryEl>
      {titleProps.title && <SectionEntryTitle {...titleProps} />}
      {renderBody && <article>{renderBody()}</article>}
      {liveLink && (
        <Link icon={FiCast} openInNewWindow to={liveLink} name="Live" />
      )}
      {codeLink && (
        <Link icon={FiCode} openInNewWindow to={codeLink} name="Code" />
      )}
    </SectionEntryEl>
  );
};

SectionEntry.propTypes = {
  renderBody: PropTypes.func,
  title: PropTypes.string,
  titleLink: PropTypes.string,
  codeLink: PropTypes.string,
  liveLink: PropTypes.string
};

export default SectionEntry;
