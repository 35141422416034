import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const SectionEl = styled.div`
  margin-bottom: 4.5rem;

  h2 {
    font-size: 1.4rem;
    font-weight: lighter;
    margin-left: 8px;
  }
`;

const TitleEl = styled.div`
  display: flex;
  width: max-content;
  position: relative;
  padding: 0 40px;
`;

const StrikethroughEl = styled.div`
  border-bottom: 2px solid black;
  position: absolute;
  bottom: 2.1rem;
  left: 0;
  width: 100%;
  z-index: 1;
`;

const TextWrapper = styled.div`
  display: flex;
  background: white;
  padding: 0 10px;
  z-index: 1000;
`;

const Section = ({ children, icon, title }) => {
  const IconComponent = icon;

  return (
    <SectionEl>
      <TitleEl>
        <TextWrapper>
          {icon && <IconComponent fontSize={"1.4rem"} />}
          <h2>{title}</h2>
        </TextWrapper>
        <StrikethroughEl />
      </TitleEl>
      <div>{children}</div>
    </SectionEl>
  );
};

Section.propTypes = {
  icon: PropTypes.any.isRequired
};

export default Section;
