import styled from "styled-components";
import COLORS from "../../constants/colors";

const StyledLink = styled.a`
  font-weight: bold;
  text-decoration: none;
  background-image: linear-gradient(
    to left,
    rgb(33, 212, 253),
    rgb(183, 33, 255)
  );
  background-repeat: no-repeat;
  background-position: right bottom;
  transition: background-position 0.5s ease 0s;
  font-size: ${({ inheritTextStyle }) =>
    inheritTextStyle ? "inherit" : "0.9rem"};
  color: ${({ inheritTextStyle }) =>
    inheritTextStyle ? "inherit" : COLORS.lightGrey};
  background-size: ${({ smallUnderline }) =>
    smallUnderline ? "200% 2px" : "200% 4px"};
  padding-bottom: ${({ smallUnderline }) => (smallUnderline ? "0" : "0.2rem")};
  transition: ${({ smallUnderline }) =>
    smallUnderline
      ? "padding-bottom 0.1s ease-in-out"
      : "padding-bottom 0.2s ease-in-out"};

  &:hover {
    background-position: left bottom;
    padding-bottom: ${({ smallUnderline }) =>
      smallUnderline ? "0.1rem" : "0.4rem"};
  }
`;

export default StyledLink;
